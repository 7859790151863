import './bootstrap';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    withCredentials: true,

});



$(document).ready(function() {
    const ticketSlider = $('.ticketSlider');
    const ticketCount = $('.ticketCount');
    const addToCartButton = $('.add-to-cart');

    ticketSlider.on('input', function() {
        const tickets = $(this).val();
        ticketCount.text(tickets);
    });

    addToCartButton.click(function(e) {
        e.preventDefault();
        const productIdHash = $(this).data('producthash');
        const button = $(this);
        const tickets = ticketSlider.val();
        const addToCartUrl = button.attr('href');
        const productIdHashValue = productIdHash;

        button.prop('disabled', true).addClass('processing');
        button.find('svg').addClass('d-none');
        button.find('b').html('<i class="spinner-border spinner-border-sm"></i>');
        button.find('span').html('<i class="spinner-border spinner-border-sm"></i>');

        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: { product_qty: tickets, product_id_hash: productIdHashValue },
            success: function(response) {
                $(".totalSum").text('€' + parseFloat(response.total_sum).toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace('.', ','));

                setTimeout(function() {
                    button.find('span').text('Add to cart');
                    button.find('b').text('');
                    button.find('svg').removeClass('d-none');

                    button.prop('disabled', false).removeClass('processing');
                    $('.basket-button').prop('disabled', false);

                }, 2000);
            },
            error: function(xhr, status, error) {
                console.error(error);
                button.find('span').text('Error');
                setTimeout(function() {
                    button.find('span').text('Add to cart');
                    button.find('b').text('');
                    button.find('svg').removeClass('d-none');
                    button.prop('disabled', false).removeClass('processing');
                }, 2000);
            }
        });
    });



});


$(document).ready(function() {

    let setupFormBlock = function($formBlock) {
        const $inputField = $formBlock.find(".inputField, .ticketSlider");
        const $decreaseButton = $formBlock.find(".decreaseButton");
        const $increaseButton = $formBlock.find(".increaseButton");
        const ticketCount = $('.ticketCount');

        if ($inputField.hasClass("ticketSlider")) {
            $decreaseButton.on("click", function() {
                if ($inputField.val() > parseInt($inputField.attr("min"))) {
                    $inputField.val(parseInt($inputField.val()) - 1);
                    ticketCount.text($inputField.val());
                }
            });

            $increaseButton.on("click", function() {
                if ($inputField.val() < parseInt($inputField.attr("max"))) {
                    $inputField.val(parseInt($inputField.val()) + 1);
                    ticketCount.text($inputField.val());
                }
            });

            $inputField.on("input", function() {
                let inputValue = $inputField.val().replace(/\D/g, "");
                const maxInputValue = parseInt($inputField.attr("max"));

                if (inputValue > maxInputValue) {
                    inputValue = maxInputValue;
                }

                $inputField.val(inputValue);

            });

        } else {
            $decreaseButton.on("click", function() {
                if ($inputField.val() > parseInt($inputField.attr("min"))) {
                    $inputField.val(parseInt($inputField.val()) - 1);
                    updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
                }
            });

            $increaseButton.on("click", function() {
                if ($inputField.val() < parseInt($inputField.attr("max"))) {
                    $inputField.val(parseInt($inputField.val()) + 1);
                    updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
                }
            });

            $inputField.on("input", function() {
                let inputValue = $inputField.val().replace(/\D/g, "");
                const maxInputValue = parseInt($inputField.attr("max"));

                if (inputValue > maxInputValue) {
                    inputValue = maxInputValue;
                }

                $inputField.val(inputValue);
                updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
            });
        }
    }

    let updateCartItemQty = function(productId, productHash, newQuantity) {
        $.ajax({
            url: "/card/update-cart",
            method: "POST",
            data: {
                product_hash: productHash,
                product_id: productId,
                quantity: newQuantity
            },
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    var productElement = $(".totalProductSum[data-product-hash='" + productHash + "']");
                    productElement.text(parseFloat(response.total_product_sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                    $(".totalSum").text('€' + parseFloat(response.total_sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                }

            },
            error: function(error) {
                console.log(error);
            }
        });
    }


    const getCartItemSum = function () {
        $.ajax({
            url: "/card/get-basket-sum",
            method: "GET",
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    $(".totalSum").text('€' + response.total_sum);
                }
            },
            error: function(error) {
                console.log(error);
            }
        });
    }




    $(".form-block").each(function() {
        setupFormBlock($(this));
    });


});


let isNotificationVisible = false;

function showActivityNotification(message) {
    if (isNotificationVisible) return;

    isNotificationVisible = true;
    let notification = $('<div class="notification_bot"></div>')
        .html(message)
        .hide()
        .appendTo('body');

    notification.fadeIn(300)
        .delay(5000)
        .fadeOut(300, function () {
            notification.remove();
            isNotificationVisible = false;
        });
}

$(document).ready(function () {
    function fetchAndShowNotification() {
        if (isNotificationVisible) return;

        $.ajax({
            url: '/api/random-product',
            method: 'GET',
            success: function (response) {
                if (response.success) {
                    let quantity = Math.floor(Math.random() * 5) + 1;
                    let totalPrice = (response.price * quantity).toFixed(2);

                    let activities = [
                        `<b>${response.name}</b> added to cart, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">just now</span>`,
                        `Order placed for <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">1 minute ago</span>`,
                        `<b>${response.name}</b> delivered to customer, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">2 minutes ago</span>`,
                        `<b>${response.name}</b> is being prepared for shipping, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">5 minutes ago</span>`,
                        `New discount applied: <b>${response.name}</b> now available for <span style="color: #dc3545; font-weight: bold;">€${response.price}</span><br><span style="font-size: 12px; color: #888;">just now</span>`,
                        `Order for <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span>, has been shipped<br><span style="font-size: 12px; color: #888;">3 minutes ago</span>`,
                        `<b>${response.name}</b> is low on stock, only a few items left<br><span style="font-size: 12px; color: #888;">just now</span>`,
                        `Flash sale: <b>${response.name}</b> purchased for <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">5 minutes ago</span>`,
                        `Customer from Berlin bought <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">1 minute ago</span>`,
                        `Exclusive deal! <b>${response.name}</b> sold for <span style="color: #dc3545; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">just now</span>`,
                        `<b>${response.name}</b> added to cart by multiple customers<br><span style="font-size: 12px; color: #888;">just now</span>`,
                        `Popular item: <b>${response.name}</b> sold out in under an hour<br><span style="font-size: 12px; color: #888;">5 minutes ago</span>`,

                        // New shipping, delivery, and order-related actions
                        `<b>${response.name}</b> is being packed in the warehouse, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">3 minutes ago</span>`,
                        `<b>${response.name}</b> shipped to customer in Paris, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">10 minutes ago</span>`,
                        `Courier is out for delivery with <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">15 minutes ago</span>`,
                        `Customer from London has confirmed receipt of <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">1 minute ago</span>`,
                        `Refund processed for <b>${response.name}</b>, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">2 minutes ago</span>`,
                        `<b>${response.name}</b> returned by customer, awaiting restocking<br><span style="font-size: 12px; color: #888;">4 minutes ago</span>`,
                        `<b>${response.name}</b> added to next-day delivery queue, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">7 minutes ago</span>`,
                        `<b>${response.name}</b> added to bulk order shipment, <span style="color: #28a745; font-weight: bold;">€${response.price}</span> (x${quantity}), total <span style="color: #28a745; font-weight: bold;">€${totalPrice}</span><br><span style="font-size: 12px; color: #888;">20 minutes ago</span>`,
                        `<b>${response.name}</b> has been packaged and is awaiting courier pickup<br><span style="font-size: 12px; color: #888;">3 minutes ago</span>`,
                        `Customer has scheduled a delivery for <b>${response.name}</b> tomorrow<br><span style="font-size: 12px; color: #888;">just now</span>`
                    ];


                    let randomActivity = activities[Math.floor(Math.random() * activities.length)];
                    showActivityNotification(randomActivity);
                }
            },

            error: function () {
                console.error('Failed to fetch product data.');
            }
        });
    }

    function startRandomNotification() {
        let interval = Math.floor(Math.random() * 15 + 1) * 1000;
        setTimeout(function () {
            fetchAndShowNotification();
            startRandomNotification();
        }, interval);
    }

    setTimeout(function () {
        fetchAndShowNotification();
        startRandomNotification();
    }, Math.floor(Math.random() * (3000 - 2000 + 1) + 2000));
});

